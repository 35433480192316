<template>
    <div class="pc-wrap">
        <div class="container">
            <h1 class="title">目录</h1>
            <ul class="blog-list">
                <li v-for="item in blogList" :key="item.id" @click="trickTips">{{item.title}}</li>
            </ul>
        </div>
        <Sidebar />
    </div>
</template>

<script>
// @ is an alias to /src
import Sidebar from '@/components/Sidebar'

export default {
    name: 'BlogList',
    components: {
        Sidebar
    },
    data() {
        return {
            blogList:[
                { id: '666', title: '第一章 做的都是什么梦啊'},
                { id: '555', title: '第二章 做的都是什么梦啊'},
                { id: '444', title: '第三章 做的都是什么梦啊'},
                { id: '333', title: '第四章 做的都是什么梦啊'},
                { id: '222', title: '第五章 做的都是什么梦啊'},
                { id: '111', title: '第六章 做的都是什么梦啊'},
            ]
        }
    },
    created() {
    },
    methods: {
        trickTips(){
            this.$toast('暂无查看权限')
        },
    }
}
</script>

<style lang="scss" scoped>
.title{
    padding-left: 20px;
}
.blog-list{
    padding-left: 80px;
    li{
        font-size: 18px;
        line-height: 48px;
        cursor: pointer;
        transition: all .2s;
        color: #333;
        padding-bottom: 20px;
        list-style: lower-greek;
        &:hover{
            color: cornflowerblue;
            text-decoration: underline;
        }
    }
}
</style>